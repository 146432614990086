import dayjs from 'dayjs';
import Config from '@/config/config';
import { getAuth } from '../auth/auth.helpers';

import Vue from 'vue';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueToast from 'vue-toast-notification';
Vue.use(VueToast);

import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');

const CONFIG = new Config();

export const listAll = async () => {
  //return distribuidoras;
  // var axios = require('axios');
  // //const { token } = getAuth();
  // const auth = getAuth();
  // var config = {
  //   method: 'get',
  //   url: `${CONFIG.API_URL}/feriado/listAll`,
  //   headers: {
  //     Bearer: auth.token
  //   }
  // };
  // return axios(config)
  //   .then(function (response) {
  //     return response.data;
  //   })
  //   .catch(function (error) {
  //     //console.log(error);
  //   });
};
