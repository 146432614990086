<template>
  <div>
    <PageHeader :title="$t('distribuidoras.title')" :items="breadcrumb" />
    <b-card class="mb-3 mt-3">
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <h4 class="text-center mb-3">Filtrar</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" class="mb-2">
            <b-form-group label="CNPJ" label-for="filtroCnpj">
              <b-form-input
                id="filtroCnpj"
                type="text"
                v-model="filtroCnpj"
                @input="filtrarDistribuidoras"
                v-mask="'##.###.###/####-##'"
              />
            </b-form-group>
          </b-col>

          <b-col md="6" class="mb-3">
            <b-form-group
              label="Nome da Distribuidora"
              label-for="filtroNomeDistribuidora"
            >
              <b-form-input
                id="filtroNomeDistribuidora"
                type="text"
                v-model="filtroNomeDistribuidora"
                @input="filtrarDistribuidoras"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="d-flex justify-content">
            <b-button variant="success" @click="redirectPage(null)">
              Cadastrar Distribuidora
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <!-- Tabela de distribuidoras com alinhamento -->
    <b-card>
      <b-container fluid>
        <b-row>
          <b-col>
            <h4 class="mb-3">Distribuidoras</h4>
            <b-table
              :items="distribuidorasFiltradas"
              :fields="tableFields"
              striped
              :bordered="false"
              responsive="sm"
            >
              <template v-slot:cell(id)="row">
                <a class="underline-hover" @click="redirectPage(row.item)">
                  {{ row.value }}
                </a>
              </template>
              <!-- Formatando a coluna CNPJ -->
              <template v-slot:cell(cnpj_distribuidora)="row">
                {{ formatarCnpj(row.item.cnpj_distribuidora) }}
              </template>
              <!-- Formatando as colunas de data e hora -->
              <template v-slot:cell(hora_ini_ponta)="row">
                {{ formatarDataHora(row.item.hora_ini_ponta) }}
              </template>
              <template v-slot:cell(hora_fim_ponta)="row">
                {{ formatarDataHora(row.item.hora_fim_ponta) }}
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask';
import PageHeader from '@/components/page-header';
import { listAll } from './distribuidora.service';

Vue.use(VueMask);

export default {
  name: 'DistribuidorasListView',
  data() {
    return {
      filtroNomeDistribuidora: '', // Filtro de Nome da Distribuidora
      distribuidoras: [], // Lista de distribuidoras
      filtroCnpj: '', // Filtro de CNPJ
      // Outros filtros de pesquisa
      distribuidorasFiltradas: [], // Distribuidoras filtradas após aplicação dos filtros
      tableFields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'cnpj_distribuidora', label: 'CNPJ', sortable: true },
        { key: 'nome_distribuidora', label: 'Razão Social', sortable: true },
        { key: 'hora_ini_ponta', label: 'Hora Início Ponta', sortable: true },
        { key: 'hora_fim_ponta', label: 'Hora Fim Ponta', sortable: true }
      ],
      breadcrumb: [
        // Caminho de navegação
        { text: 'Backoffice', href: '/' },
        { text: 'Distribuidoras', active: true }
      ]
    };
  },
  components: {
    PageHeader
  },
  methods: {
    // Método para buscar as distribuidoras
    buscarDistribuidoras() {
      listAll() // Chama o serviço para obter a lista de distribuidoras
        .then((distribuidoras) => {
          console.log(distribuidoras);
          this.distribuidoras = distribuidoras; // Preenche a lista de distribuidoras
          this.filtrarDistribuidoras(); // Aplica os filtros
        })
        .catch((error) => {
          console.error('Erro ao buscar as distribuidoras:', error);
        });
    },
    // Método para atualizar a lista de distribuidoras com base nos filtros
    filtrarDistribuidoras() {
      this.distribuidorasFiltradas = this.distribuidoras.filter(
        (distribuidora) => {
          const cnpjFormatado = this.formatarCnpj(
            distribuidora.cnpj_distribuidora
          );
          const cnpjMatch = cnpjFormatado.includes(this.filtroCnpj);
          const nomeMatch = distribuidora.nome_distribuidora
            .toLowerCase()
            .includes(this.filtroNomeDistribuidora.toLowerCase());
          return cnpjMatch && nomeMatch;
        }
      );
    },
    redirectPage(distribuidora) {
      // Redireciona para a página de edição ou criação
      if (distribuidora === null) {
        this.$router.push('/edit-distribuidoras');
      } else {
        this.$router.push({
          path: '/edit-distribuidoras',
          query: { distribuidora: JSON.stringify(distribuidora) }
        });
      }
    },
    // Função para formatar o CNPJ com máscara
    formatarCnpj(cnpj) {
      if (cnpj) {
        return cnpj.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          '$1.$2.$3/$4-$5'
        );
      }
      return '';
    },
    // Função para formatar data e hora no padrão brasileiro
    formatarDataHora(dataHora) {
      if (dataHora) {
        const dateTime = new Date(dataHora);
        const horaMinuto = dateTime.toLocaleTimeString('pt-BR', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        });
        return horaMinuto;
      }
      return '';
    }
  },
  mounted() {
    this.buscarDistribuidoras(); // Carrega as distribuidoras ao montar o componente
  }
};
</script>

<style>
.filtros {
  display: flex;
  justify-content: space-between; /* Alinhar os itens no espaço disponível */
  flex-wrap: wrap; /* Permitir que os itens se ajustem a diferentes tamanhos de tela */
}

.filtro-item {
  flex-grow: 1;
  padding: 15px;
}
</style>
